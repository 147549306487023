.templateEditor {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 20px auto;
  max-width: 1900px;
  min-height: 500px;
  font-family: 'Arial', sans-serif;
  display: flex; /* Use flexbox to display editor and image gallery side by side */
  flex-direction: row; /* Side-by-side layout */
  justify-content: space-between; /* Space between the columns */
}

.editorWrapper {
  display: flex;
  width: 100%;
}

.editorContainer {
  flex: 1; /* Takes up remaining space */
  margin-right: 20px; /* Space between the editor and the image gallery */
}

.imageGalleryContainer {
  width: 600px; /* Fixed width for the image gallery */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the gallery content */
  border: 1px solid #ddd; /* Optional: Add a border around the gallery box */
  border-radius: 8px; /* Optional: Round the corners */
  padding: 10px; /* Add some padding around the gallery */
  background-color: #fff; /* Optional: Set background color */
  max-height: 1200px; /* Limit the height of the image gallery */
  overflow-y: auto; /* Enable vertical scrolling when the content overflows */
}

.imageGallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
  padding: 0;
  margin: 0;
  object-fit: cover;
}

.galleryImage {
  width: 170px;
  height: 150px;
  border-radius: 2px;
  display: block;
}

.imageItem {
  width: 160px;
  height: 150px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: cover;
}

.durationOverlay {
  position: absolute;
  bottom: 8px; /* Adjust to your preference */
  right: 12px; /* Adjust to your preference */
  background-color: rgba(0, 0, 0, 0.452); /* Semi-transparent black */
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem; /* Adjust size as needed */
  z-index: 2; /* Ensure it appears on top of the image */
}

.keywordsOverlay {
  position: absolute;
  bottom: 8px;
  width: 160px;
  height: 150px;
  left: 8px;
  background: rgba(0, 0, 0, 0);
  color: white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  z-index: 10; 
  
}

// .galleryImage {
//   width: 100%;
//   height: auto;
// }

@media screen and (max-width: 768px) {
  .templateEditor {
    flex-direction: column; /* Stack editor and image gallery on smaller screens */
  }

  .imageGalleryContainer {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Space above gallery */
  }

  .editorContainer {
    margin-right: 0; /* No margin on smaller screens */
  }
}

.inputField {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}

.dropdown {
  width: 100%;
  padding: 12px;
  margin: 10px 0 20px 0; /* Added bottom margin for gap */
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}

.saveButton {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  background-color: #ff9500;
  color: #fff;
  border: none;
  border-radius: 8px; /* Rounded edges */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #cf7317;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(1px); /* Pressed effect */
  }
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none;
  margin-bottom: 29px; 
}

.topic {
  text-align: center;
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: center;
}

.refreshButton {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
}

.refreshButton:hover {
  background-color: #e87e00; /* Darker shade on hover */
}


.imageItemContainer {
  position: relative;
  display: inline-block;
  margin: 10px;
  transition: transform 0.2s ease-in-out;
}

.imageItemContainer:hover {
  transform: scale(1.05);
}

// .galleryImage {
//   width: 200px;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 8px;
// }

.deleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.8);
  color: rgb(239, 9, 9);
  visibility: hidden;
  z-index: 10;
  transition: visibility 0.3s, opacity 0.3s ease;
}

.imageItemContainer:hover .deleteButton {
  visibility: visible;
  opacity: 1;
}

.uploadButton {
  font-size: 15px;
  padding: 12px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  background-color: #003366;
  color: white;
}

.infoButton {
  margin-left: 10px;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.tabContainer {
  display: flex; 
  justify-content: space-around; 
  align-items: center; 
  border-bottom: 2px solid #ccc; 
  margin-bottom: 1rem; 
}

.tabButton {
  padding: 10px 20px;
  margin-bottom: 8px;
  border: none; 
  background-color: transparent; 
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth hover effects */
}

.tabButton:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  color: #007bff; /* Blue text on hover */
}

.activeTab {
  font-weight: bold; /* Highlight active tab */
  color: #007bff; /* Active tab text color */
  border-bottom: 3px solid #007bff; /* Underline the active tab */
}


.imageWrapper {
  position: relative;
  display: inline-block;
}



.confidentialIcon {
  position: absolute;
  top: 10px; 
  right: 10px;
  background-color: rgb(0, 0, 0); 
  padding: 5px;
  border-radius: 50%;
  color: rgb(249, 192, 4);
  font-size: 24px;
  z-index: 1;
}


.searchBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  width: 600px;
}

.searchInput {
  width: 2000px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}


.searchButton {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 2px;
}

.searchButton:hover {
  background-color: #0056b3;
}

.categoryChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.categoryChip {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 500;
}

.categoryChip:hover {
  background-color: #e0e0e0;
}

.selectedCategory {
  background-color: orange;
  color: white;
  border-color: orange;
  font-weight: bold;
}

